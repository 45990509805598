<template>
  <section class="main-body">

    <self-help location="overlays" />

    <MiniHeader :heading="'Overlay Promos'" :buttonText="'Add Overlay Promo'" :hasButton="true" @buttonClicked="createOverlayPromo()" />

    <p>Manage your overlay promos here. The order of display priority is seen in descending order and can be changed by dragging and dropping the promos in to the desired order.</p>

    <div class="quick-disable" :class="[overlayFeedDisabled && !unsavedStatus ? 'is-disabled' : '', !overlayFeedDisabled && !unsavedStatus ? 'is-enabled' : '']">
      <p class="quick-disable__label">Overlay Promos are:</p>

      <span class="quick-disable__select">
        <v-select
          id="overlay-status"
          :items="[
            {
              label: 'Enabled',
              value: false
            },
            {
              label: 'Disabled',
              value: true
            },
          ]"
          item-text="label"
          item-value="value"
          v-model="overlayFeedDisabled"
          solo
          flat
          @change="unsavedStatus = true">
        </v-select>
      </span>

      <v-btn @click="updatePromoStatus()">Update</v-btn>

      <p v-if="unsavedStatus" class="quick-disable__label"><i>Status is unsaved</i></p>
    </div>

    <draggable
      v-if="overlayPromos"
      v-model="overlayPromos"
      @start="drag=true"
      @end="drag=false, updated()">

      <transition-group name="list-complete" tag="ol" class="config-selector__list" :class="[overlayFeedDisabled && !unsavedStatus || unsavedStatus ? 'promos-disabled' : '']">
        <li class="config-selector__item draggable-item" v-for="item in overlayPromos" :key="item.promoID">
          <div class="config-selector__content">
            <div class="config-selector__image" :style="`background-image: url(${imageDomain}/${item.imageKey})`"></div>
            <div>
              <p v-if="!item.published" class="status status--draft">Draft</p>
              <template v-else>
                <p v-if="timeNow < item.startDateTime" class="status status--scheduled">Scheduled</p>
                <p v-else-if="item.startDateTime < timeNow && timeNow < item.endDateTime" class="status status--active">Active</p>
                <p v-else-if="timeNow > item.endDateTime" class="status status--expired">Expired</p>
              </template>
              <h2 class="config-selector__subheading subheading">{{ item.title }}</h2>
              <p class="id-label">Active from <strong>{{ item.startDateTime | moment('HH:mm on Do MMM YY') }}</strong> until <strong>{{ item.endDateTime | moment('HH:mm on Do MMM YY') }}</strong></p>
            </div>
          </div>
          <div>
            <v-btn :to="`/overlay-promos/${item.promoID}`">Edit</v-btn>
            <v-btn @click="deleteOverlayPromo(item.promoID)" color="error">Delete</v-btn>
          </div>
        </li>
      </transition-group>
    </draggable>

    <StickyFooter :errorMessage="errorMessage" :status="status" :buttonText="'Update rows'" @buttonClicked="saveOverlayPromoData()" :allowPreview="false"/>
  </section>
</template>

<script>
import axios from "axios"
import draggable from "vuedraggable"
import MiniHeader from "@/components/Library/MiniHeader"
import StickyFooter from "@/components/Library/StickyFooter"
import SelfHelp from "@/components/Library/SelfHelp"
import moment from "moment"


export default {
  name: "OverlayPromosDashboard",

  components: {
    MiniHeader,
    draggable,
    StickyFooter,
    SelfHelp
  },

  data: () => ({
    api: `${process.env.VUE_APP_OVERLAYSAPI}/promo-overlays`,
    overlayPromos: null,
    promoOrderUpdated: false,
    status: "saved",
    errorMessage: "",
    imageDomain: process.env.VUE_APP_IMAGEURL,
    timeNow: "",
    overlayFeedDisabled: null,
    unsavedStatus: false
  }),

  mounted() {
    console.log("Overlay promo dashboard mounted");
    this.fetchOverlayPromoStatus()
    this.fetchOverlayPromoData();
    this.timeNow = moment().utc().format()
  },

  beforeRouteLeave (to, from, next) {
    if (this.promoOrderUpdated) {
      const answer = window.confirm("Do you really want to leave? You have unsaved changes to the promo display order!")
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },

  methods: {
    updatePromoStatus() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PUT",
          url: `${this.api}/globalsetting/update`,
          headers: { "Authorization": jwt },
          data: {
            disablePromos: this.overlayFeedDisabled
          }
        })
        .then(response => {
          console.log("Overlay promo status updated", response)
          this.status = "saved";
          this.unsavedStatus = false
        })
        .catch(e => {
          console.error("Problem updating overlay promo status", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem updating overlay promo";
          this.$store.commit("completeLoading");
        });
      })
    },

    fetchOverlayPromoStatus() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}/globalsetting/get`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("fetchOverlayPromoData config: ", response);
          this.overlayFeedDisabled = response.data.disablePromos;
          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving overlay promo config data", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    fetchOverlayPromoData() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("fetchOverlayPromoData: ", response);
          this.overlayPromos = response.data.body;
          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving overlay promo data", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    saveOverlayPromoData() {
      for (let i = 0; i < this.overlayPromos.length; i++) {
        const promo = this.overlayPromos[i]
        promo.forcedOrder = i;

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "PUT",
            url: `${this.api}/update`,
            headers: { "Authorization": jwt },
            data: promo
          })
          .then(response => {
            console.log("Overlay promo updated", response)
            this.status = "saved";
            this.promoOrderUpdated = false;
          })
          .catch(e => {
            console.error("Problem updating overlay promo", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem updating overlay promo";
            this.$store.commit("completeLoading");
          });
        })
      }
    },

    createOverlayPromo() {
      if (this.overlayPromos.length >= 30) {
        window.alert("You have reached your max promo limit (30). Please delete old and unnecessary promos to make room.")
      } else {
       this.$router.push({ path: "overlay-promos/create" })
      }
    },

    updated() {
      this.promoOrderUpdated = true
      this.status = "needsSaving";
    },

    deleteOverlayPromo(promoID) {
      if (confirm("Are you sure you want to delete this?")) {
        console.warn("Deleting")
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "DELETE",
            url: `${this.api}/delete?promoID=${promoID}`,
            headers: { "Authorization": jwt },
          })
          .then(response => {
            console.log("Overlay promo deleted", response);
            this.fetchOverlayPromoData();
          })
          .catch(e => {
            console.error("Problem deleting overlay promo", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem deleting overlay promo";
          });
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @keyframes pulse {
    0% {
      border-bottom-color: #f7e4cb;
    }
    100% {
      border-bottom-color: #e7a793;
    }
  }

  .quick-disable {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 32px 16px 16px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-bottom-width: 4px;
    border-radius: 6px;
    transition: background-color 0.2s ease-out, border 0.2s ease-out;

    &.is-disabled {
      animation: pulse 1.4s infinite;
      animation-direction: alternate;
    }

    &.is-enabled {
      border-bottom-color: rgb(141, 199, 150);
    }

    &__label {
      margin: 12px 8px 0 0;
    }

    &__select {

    }
  }

  .config-selector {
    &__list {
      padding: 0;
      margin: 20px 0 0;

      &.promos-disabled {
        opacity: 0.5;
      }
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      margin: 0 0 16px;
      border: 1px solid #67247c;
      border-radius: 6px;
      background-color: white;
    }

    &__content {
      display: flex;
      align-items: center;
    }

    &__subheading {
      margin: 0;
      padding: 5px 0 0;
    }

    &__image {
      height: 150px;
      width: 200px;
      margin-right: 16px;
      background: center / contain no-repeat;
      border: 1px dashed grey;
    }
  }

  .id-label {
    color: #666;
    font-size: 12px;
    opacity: 0.7;
    margin: 3px 0 0;
  }

  .status {
    display: inline-block;
    padding: 3px 12px;
    margin: 0 8px 8px 0;
    font-size: 10px;
    color: #fff;
    background-color: #E6E8F1;
    border-radius: 4px;

    &--draft {
      background-color: color(draftHighlight);
    }

    &--scheduled {
      background-color: color(newHighlight);
    }

    &--active {
      background-color: #5fad41;
    }

    &--expired {
      background-color: color(hiddenHighlight);
    }
  }

  .filters {
    display: flex;
    flex-direction: row;
    &__order {
      margin: 0 0 0 auto;
      max-width: 240px;
    }
  }

  .list-complete-enter, .list-complete-leave-to {
    opacity: 0;
  }

  .list-complete-leave-active {
    position: absolute;
    width: calc(100% - (var(--body-gutter) * 2));
  }

  .draggable-item {
    transition: all 0.5s, opacity 0.2s;
  }
</style>
