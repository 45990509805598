import { render, staticRenderFns } from "./OverlayPromosDashboard.vue?vue&type=template&id=0c71abd3&scoped=true"
import script from "./OverlayPromosDashboard.vue?vue&type=script&lang=js"
export * from "./OverlayPromosDashboard.vue?vue&type=script&lang=js"
import style0 from "./OverlayPromosDashboard.vue?vue&type=style&index=0&id=0c71abd3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c71abd3",
  null
  
)

export default component.exports